
  .add-comment {
    textarea {
        display: block;
        margin-bottom: 15px;
        width: 100%;
        padding:10px 7px;
        border: 2px solid #ddd;
        background-color: #fff;
        border-bottom: 1px solid #ccc;
        border-radius: 5px;
      
      }

  }