.table-responsive {
    table .avatar {
        width:25%;
    }
    table tr {
        color:red
    }
    tr {
        align-items: center;
        border: 1px solid rgb(246, 244, 244);
        border-radius: 5px;
    }
}