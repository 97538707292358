.socialsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin: 50px auto;
  display: flex;
  padding: 3px;
  height: 60px;

  i {
    font-size: 50px;
  }
}

@media (max-width: 768px) {
  .socialsWrapper {
    width: 100% important;
    justify-content: space-around;
    div {
      width: 100% important;
    }
  }
}
