.course-section {
  width: 100vw;
  padding: 0px 50px;
}

.courses {
  height: auto !important;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  
}

.course-card {
  width: 25%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.45);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 5px;
  padding: 25px;
  margin: 30px 50px 30px 30px;
  background: white;
}

.course-card:hover {
  box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.65);
}

.course-card-item img {
  display: block;
  width: 100%;
  border-radius: 5px;
  filter: blur(1);
}

.course-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, 500px);
}

.course-card>div {
  margin: 10px auto;
}

.course-card-body {}

.course-first-card-item {}

.stars {
  width: 10px;
  max-width: 80px;
  display: block;
  margin: 5px 0px;
}

.done {
  border-bottom: 15px solid #21bf73;
}

.ongoing {
  border-bottom: 15px solid #fddb3a;
}

.coming {
  border-bottom: 15px solid #fd5e53;
}

.course-card-title {
  font-size: 20px;
  padding: 2px 0;
  font-weight: 500;
  text-transform: uppercase;
}

.course-card-subtitle {
  font-size: 18px;
  padding: 2px 0;
  margin: 3px 0;
}

@media (max-width: 768px) {
  .add-post-wrapper {
    width: 100%;
    padding: 30px 15px;
  }

  .single-post-wrapper {
    padding: 30px 15px;
  }

  .add-post-wrapper form {
    width: 100%;
    margin: auto;
  }

  .course-section {
    width: 100vw;
    padding: 0;

  }

  .courses {
    width: 100%;
    display: flex;
    flex-direction: column;



  }

  .course-card {
    width: 100%;
    padding: 15;
    font-size: 1rem;
    margin: 15px;

  }
}