
/* Shimmer Placeholder Style */
.shimmer {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
}

.shimmer-box {
  border-radius: 8px;
  height: 100%;
  width: 100%;
}

.shimmer-text {
  height: 20px;
  margin-top: 10px;
  width: 80%;
  background: #e0e0e0;
}

/* Shimmer Animation */
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}
